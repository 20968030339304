import './App.scss';
import workIcon from './assets/img/vacancies.png';
import telephoneIcon from './assets/img/telephone.png';
import telegramIcon from './assets/img/telegram.png';

function App() {
    return (
        <div className="main">
            <h1 className="main__title">Благодарим Вас за <span className="blue">отклик</span>!</h1>
            <div className="main__info">
                <span className="desc">Для записи на собеседование, пожалуйста, выберите вакансию и заполните короткую онлайн-анкету, нажав на кнопку ниже:</span>
                <a href="https://workpro.com.ru" className="btn">
                    <img src={workIcon}></img>
                    <span>Вакансии</span>
                </a>

                <div className="additional">
                    <ul>
                        <li>
                            <img src={telephoneIcon}></img>
                            <span>После с Вами свяжется менеджер для обсуждения дальшейшего трудоустройства. </span>
                        </li>
                        <li>
                            <img src={telegramIcon}></img>
                            <span>Предлагаем вам также подписаться на наш канал в <a href="https://t.me/VVORKPRO" className="telegram">Telegram</a>, где Вы найдете еще больше свежих вакансий.</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default App;
